<template>
  <div class="information">
    <a-form
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
      layout="vertical"
      ref="formRef"
      :validate-trigger="['change', 'blur']"
    >
      <!--      <a-form-item label="关系组">-->
      <div class="phone">
        <div class="phoneTitle">关系组</div>
        <div @click="addGroup" class="addPhone">添加</div>
      </div>

      <div v-if="form.contactGroups.length === 0" class="groupCalss groupCol">
        添加关系组
      </div>
      <div v-else class="groupCalss">
        <div
          v-for="item in form.contactGroups"
          :key="item.id"
          class="groupContent"
        >
          <div class="name">{{ item.groupName }}</div>
          <div class="delete" @click="handleDel(item, index, 'group')">
            <img src="@/assets/images/box/delete.png" alt="" class="groupDel" />
          </div>
        </div>
      </div>

      <div class="phone">
        <div class="phoneTitle">人脉圈</div>
        <div @click="addConnectionsQuery" class="addPhone">添加</div>
      </div>

      <div
        v-if="form.connectionsQuery.length === 0"
        class="groupCalss groupCol"
      >
        添加到人脉圈
      </div>
      <div v-else class="groupCalss">
        <div
          v-for="(item, index) in form.connectionsQuery"
          :key="item.id"
          class="groupContent"
        >
          <div class="name">{{ item.connectionsName }}</div>
          <div
            class="delete"
            @click="handleDel(item, index, 'connectionsQuery')"
          >
            <img src="@/assets/images/box/delete.png" alt="" class="groupDel" />
          </div>
        </div>
      </div>

      <div class="phone">
        <div class="phoneTitle">电话</div>
        <div @click="addPhone" class="addPhone">添加</div>
      </div>
      <div
        v-if="form.contactDetailsPhoneList.length === 0"
        class="groupCalss groupCol"
      >
        请添加电话
      </div>

      <div v-else>
        <div
          v-for="(item, index) in form.contactDetailsPhoneList"
          :key="item.id"
          class="phoneCalss"
        >
          <!--      v-bind="validateInfos.item.phone"    -->
          <!--        <div>{{ validateInfos }}</div>-->
          <a-form-item
            label=""
            class="phoneCalssLeft"
            v-bind="validateInfos.phone"
          >
            <a-input
              v-model:value="item.phone"
              placeholder="添加电话号码"
              @blur="handleBlur('phone', item)"
              :disabled="showDisabled"
            />
          </a-form-item>

          <a-select
            v-model:value="item.type"
            placeholder="类型"
            class="phoneCalssRight"
            @blur="handleBlur('phoneType', item)"
            :disabled="showDisabled"
          >
            <a-select-option
              v-for="item in typeList"
              :key="item.value"
              :value="item.value"
              :disabled="showDisabled"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>

          <div class="delete" @click="handleDel(item, index, 'phone')">
            <img
              src="@/assets/images/box/contactDel.png"
              alt=""
              class="delete-img"
            />
          </div>
        </div>
      </div>

      <div class="phone">
        <div class="phoneTitle">邮箱</div>
        <div @click="addEamil" class="addPhone">添加</div>
      </div>

      <div
        v-if="form.contactDetailsEmailList.length === 0"
        class="groupCalss groupCol"
      >
        请添加邮箱地址
      </div>
      <div v-else>
        <div
          v-for="(item, index) in form.contactDetailsEmailList"
          :key="item.id"
          class="phoneCalss"
        >
          <a-form-item
            label=""
            class="phoneCalssLeft"
            v-bind="emailValidate.validateInfos.email"
          >
            <a-input
              v-model:value="item.email"
              placeholder="添加邮箱地址"
              @blur="handleBlur('email', item)"
            />
          </a-form-item>

          <a-select
            v-model:value="item.type"
            placeholder="类型"
            class="phoneCalssRight"
            @blur="handleBlur('emailType', item)"
          >
            <a-select-option
              v-for="item in typeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <div class="delete" @click="handleDel(item, index, 'email')">
            <img
              src="@/assets/images/box/contactDel.png"
              alt=""
              class="delete-img"
            />
          </div>
        </div>
      </div>

      <!--      </a-form-item>-->
      <a-form-item label="公司" class="mt-10">
        <a-textarea
          v-model:value="form.company"
          placeholder="请输入联系人所在公司"
          @blur="handleBlur('company', form)"
        />
      </a-form-item>

      <a-form-item label="生日" class="mt-10 birthday">
        <a-date-picker
          v-model:value="form.birthday"
          :getCalendarContainer="triggerNode => triggerNode.parentNode"
          value-format="yyyy-MM-DD"
          :disabled-date="disabledDate"
          placeholder="请选择联系人生日"
          style="width: 100%"
          class="phoneCalssRight"
          :inputReadOnly="true"
          @blur="handleBlur('birthday', form)"
        />
      </a-form-item>

      <a-form-item label="性别" class="interaction gender mt-10">
        <a-select
          v-model:value="form.sex"
          placeholder="请选择联系人性别"
          @blur="handleBlur('sex', form)"
        >
          <a-select-option
            v-for="item in sexList"
            :key="item.itemCode"
            :value="item.itemCode"
          >
            {{ item.itemNameCn }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="兴趣爱好" class="mt-10">
        <a-textarea
          v-model:value="form.hobby"
          placeholder="请输入联系人兴趣爱好"
          @blur="handleBlur('hobby', form)"
        />
      </a-form-item>

      <a-form-item label="地域" class="mt-10">
        <a-input
          v-model:value="form.location"
          placeholder="请输入联系人地域信息"
          @blur="handleBlur('location', form)"
        />
      </a-form-item>

      <div class="phone">
        <div class="phoneTitle">地址</div>
        <div @click="addAddress" class="addPhone">添加</div>
      </div>
      <!--    a-textarea  -->
      <div
        v-if="form.contactDetailsAddressList.length === 0"
        class="groupCalss groupCol"
      >
        请添加地址
      </div>
      <div v-else>
        <div
          v-for="(item, index) in form.contactDetailsAddressList"
          :key="item.id"
          class="phoneCalss"
        >
          <a-form-item
            label=""
            class="address"
            v-bind="addressValidate.validateInfos.address"
          >
            <a-textarea
              v-model:value="item.address"
              placeholder="请输入联系人地址"
              @blur="handleBlur('address', item)"
            />
          </a-form-item>
          <div class="delete" @click="handleDel(item, index, 'address')">
            <img
              src="@/assets/images/box/contactDel.png"
              alt=""
              class="delete-img"
            />
          </div>
        </div>
      </div>
    </a-form>
  </div>
  <iss-add-group
    v-model:visible="visibleAddGroup"
    :init="activeItemAddGroup"
    @fnOk="handleAddGroup"
  />
  <iss-pulse
    v-model:visible="visiblePulsePage"
    :init="activeItemPulse"
    @fnOk="handlePulse"
  />
</template>

<script>
import { Form, Select, message, DatePicker } from 'ant-design-vue';
import { reactive, toRefs, ref, onMounted, watch } from 'vue';
import IssAddGroup from '@/views/contacts/contactAall/addGroup';
import IssPulse from '@/components/pulse';
import moment from 'moment';
import { emailRegExp, mobileRegExp } from '@/utils/validation';
import contactAll from '@/api/contactAll';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { local } from '@/utils/storage';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    // ARow:Row,
    // ACol:Col,
    IssAddGroup,
    IssPulse,
    ADatePicker: DatePicker,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { id } = route.params;
    // const { proxy } = getCurrentInstance();
    const formRef = ref();
    const state = reactive({
      visibleAddGroup: false,
      visiblePulsePage: false,
      activeItemPulse: [],
      activeItemAddGroup: {},
      sexList: [],
      typeList: [
        { value: 3, label: '其他' },
        { value: 2, label: '家庭' },
        { value: 1, label: '工作' },
      ],
      phoneIndex: '',
      showDisabled: false,
    });
    const form = reactive({
      // 关系组
      contactGroups: [],
      connectionsQuery: [],
      contactDetailsPhoneList: [
        {
          userId: '',
          contactId: '',
          phone: '',
          type: null,
          infoType: 1,
          // `${randoms(3)}`
          id: '',
          name: '家庭',
        },
      ],
      contactDetailsEmailList: [
        {
          userId: '',
          contactId: '',
          email: '',
          type: null,
          infoType: 2,
          id: '',
        },
      ],
      company: '',
      birthday: '',
      hobby: '',
      location: '',
      sex: null,
      contactDetailsAddressList: [
        {
          userId: '',
          contactId: '',
          address: '',
          infoType: 3,
          id: '',
        },
      ],
    });

    // resetFields  validate,
    const required = { required: true, whitespace: true, message: '不能为空' };

    const { validateInfos } = Form.useForm(
      form.contactDetailsPhoneList[state.phoneIndex],
      {
        phone: [
          required,
          { pattern: mobileRegExp, message: '请输入合法的手机号码' },
        ],
      }
    );
    const emailValidate = Form.useForm(form.contactDetailsEmailList, {
      email: [{ pattern: emailRegExp, message: '请输入正确的邮箱地址' }],
    });

    const addressValidate = Form.useForm(form.contactDetailsAddressList, {
      address: [{ max: 56, message: '字符长度不能超过56' }],
    });

    const formValidate = Form.useForm(form, {
      company: [{ max: 56, message: '字符长度不能超过56' }],
      hobby: [{ max: 200, message: '字符长度不能超过200' }],
    });

    // 路由变化调取接口页面查询接口
    watch(
      () => route.params.id,
      value => {
        if (value && route.name === 'contactDetails') {
          perSonalInfo();
        }
      }
    );

    const perSonalInfo = () => {
      contactAll
        .getContactDetail('', {
          userId: store.state.account.user.id,
          contactId: route.params.id,
        })
        .then(res => {
          // console.log('cahxun', res);
          if (res) {
            // 把数据存储一份到local
            local.set('infolist', res);
            Object.assign(form, res);
          }
        });
    };

    // 性别下拉
    contactAll.getTree('', { code: 'SEX' }).then(res => {
      if (res) {
        state.sexList = res;
      }
    });

    onMounted(() => {
      perSonalInfo();
    });

    const save = parmas => {
      contactAll.getSave('', parmas).then(res => {
        if (res) {
          message.success('操作成功');
          perSonalInfo();
        }
      });
    };
    // 先看电话和邮箱,地址,再看电话和邮箱 类型
    const upDataPhone = (data, parmas, type) => {
      console.log('form.contactDetailsPhoneList', form.contactDetailsPhoneList)
      if (type === 'phoneType') {
        // 处理电话类型
        let foundMatch = false;
        data.contactDetailsPhoneList.forEach(element => {
          // 编辑更新有id, 需要判断id是否相同,相同则判断内容是否相同,内容相同则跳过, 内容不相同则调取接口
          if (parmas.id !== '' && parmas.id === element.id) {
            if (parmas.type === element.type) {
              console.log('内容相同');
            } else {
              save(parmas);
            }
            foundMatch = true;
          }
        });

        // 新增没有id,判断是否有内容,有则调取接口,没有则跳过
        if (parmas.id === '' && !foundMatch) {
          if (parmas.type) {
            save(parmas);
          }
        }
      } else {
        // 处理电话\邮箱\地址
        let foundMatch = false;
        data.contactDetailsPhoneList.forEach(element => {
          // 编辑更新有id, 需要判断id是否相同,相同则判断内容是否相同,内容相同则跳过, 内容不相同则调取接口
          if (parmas.id !== '' && parmas.id === element.id) {
            if (parmas.phone === element.phone) {
              // 编辑时号码相同，则不调接口
              console.log('电话电话 内容相同');
            } else {
              console.log(
                'Ids match, but 电话 values are different.内容不相同'
              );
              save(parmas);
            }
            foundMatch = true;
          }
        });

        // 新增没有id,判断是否有内容,有则调取接口,没有则跳过
        if (parmas.id === '' && !foundMatch) {
          if (parmas.phone) {
            save(parmas);
          }
        }
      }
    };
    const upDataAddress = (data, parmas) => {
      // 处理电话\邮箱\地址
      let foundMatch = false;
      data.contactDetailsAddressList.forEach(element => {
        // 编辑更新有id, 需要判断id是否相同,相同则判断内容是否相同,内容相同则跳过, 内容不相同则调取接口
        if (parmas.id !== '' && parmas.id === element.id) {
          if (parmas.address === element.address) {
            console.log('.内容相同');
          } else {
            save(parmas);
          }
          foundMatch = true;
        }
      });

      // 新增没有id,判断是否有内容,有则调取接口,没有则跳过
      if (parmas.id === '' && !foundMatch) {
        if (parmas.address) {
          save(parmas);
        }
      }
    };
    const upDataEmail = (data, parmas, types) => {
      if (types === 'emailType') {
        // 邮箱type
        let foundMatch = false;
        data.contactDetailsEmailList.forEach(element => {
          // 编辑更新有id, 需要判断id是否相同,相同则判断内容是否相同,内容相同则跳过, 内容不相同则调取接口
          if (parmas.id !== '' && parmas.id === element.id) {
            if (parmas.type === element.type) {
              console.log('内容相同');
            } else {
              save(parmas);
            }
            foundMatch = true;
          }
        });

        // 新增没有id,判断是否有内容,有则调取接口,没有则跳过
        if (parmas.id === '' && !foundMatch) {
          if (parmas.type) {
            save(parmas);
          }
        }
      } else {
        // 处理电话\邮箱\地址
        let foundMatch = false;
        // console.log('contactDetailsPhoneList', data.contactDetailsPhoneList);
        data.contactDetailsEmailList.forEach(element => {
          // 编辑更新有id, 需要判断id是否相同,相同则判断内容是否相同,内容相同则跳过, 内容不相同则调取接口
          if (parmas.id !== '' && parmas.id === element.id) {
            if (parmas.email === element.email) {
              console.log('内容相同');
            } else {
              save(parmas);
            }
            foundMatch = true;
          }
        });

        // 新增没有id,判断是否有内容,有则调取接口,没有则跳过
        if (parmas.id === '' && !foundMatch) {
          if (parmas.email) {
            save(parmas);
          }
        }
      }
    };

    return {
      formRef,
      form,
      id,
      perSonalInfo,
      ...toRefs(state),
      validateInfos,
      emailValidate,
      addressValidate,
      formValidate,

      handleDel(item, index, type) {
        if (type === 'group') {
          contactAll.contactGroups('', { ids: [item.id] }).then(res => {
            if (res) {
              message.success('操作成功');
              perSonalInfo();
            }
          });
        } else if (type === 'connectionsQuery') {
          contactAll.connectionsQuery('', { ids: [item.id] }).then(res => {
            if (res) {
              message.success('操作成功');
              perSonalInfo();
            }
          });
        } else if (type === 'phone') {
          if (form.contactDetailsPhoneList.length === 1) {
            return message.error('手机号最少保留一个');
          } else {
            if (item.phone) {
              let id = item.id;
              contactAll.deleteById('', id).then(res => {
                if (res) {
                  message.success('操作成功'), perSonalInfo();
                }
              });
            } else {
              return form.contactDetailsPhoneList.splice(index, 1);
            }
          }
        } else if (type === 'email') {
          // if (form.contactDetailsEmailList.length === 1) {
          //   return message.error('邮箱最少保留一个');
          // } else {

          if (item.email) {
            let id = item.id;
            contactAll.deleteById('', id).then(res => {
              if (res) {
                message.success('操作成功'), perSonalInfo();
              }
            });
          } else {
            return form.contactDetailsEmailList.splice(index, 1);
          }

          // }
        } else {
          // if (form.contactDetailsAddressList.length === 1) {
          //   return message.error('至少保留一项数据');
          // } else {

          if (item.address) {
            let id = item.id;
            contactAll.deleteById('', id).then(res => {
              if (res) {
                message.success('操作成功'), perSonalInfo();
              }
            });
          } else {
            return form.contactDetailsAddressList.splice(index, 1);
          }

          // }
        }
      },
      addGroup() {
        state.visibleAddGroup = true;
        state.activeItemAddGroup = [route.params.id];
      },
      addConnectionsQuery() {
        state.visiblePulsePage = true;
        state.activeItemPulse = [route.params.id];
      },
      handlePulse(value) {
        state.visiblePulsePage = value;
        perSonalInfo();
      },
      handleAddGroup() {
        state.visibleAddGroup = false;
        perSonalInfo();
      },
      addPhone() {
        let obj = {
          userId: '',
          contactId: '',
          phone: '',
          type: null,
          infoType: 1,
          // `${randoms(3)}`
          id: '',
          name: '家庭',
        };
        form.contactDetailsPhoneList.push(obj);
      },
      addEamil() {
        let obj = {
          userId: '',
          contactId: '',
          email: '',
          type: null,
          infoType: 2,
          id: '',
        };
        form.contactDetailsEmailList.push(obj);
      },
      addAddress() {
        let obj = {
          userId: '',
          contactId: '',
          address: '',
          id: '',
        };
        form.contactDetailsAddressList.push(obj);
      },

      disabledDate: current => {
        return current && current > moment().endOf('day');
      },

      handleBlur(type, item) {
        let data = local.get('infolist');
        if (
          type === 'phone' ||
          type === 'email' ||
          type === 'address' ||
          type === 'emailType' ||
          type === 'phoneType'
        ) {
          let parmas = {
            userId: store.state.account.user.id,
            contactId: id,
            phone: '',
            email: '',
            address: '',
            type: '',
            infoType: '',
            id: '',
          };

          if (type === 'phone') {
            if (item.id) {
              parmas.phone = item.phone;
              parmas.id = item.id;
            } else {
              parmas.phone = item.phone;
              parmas.id = '';
            }
            parmas.infoType = 1;
            // 调取接口
            upDataPhone(data, parmas, type);
          }
          if (type === 'address') {
            if (item.id) {
              parmas.address = item.address;
              parmas.id = item.id;
            } else {
              parmas.address = item.address;
              parmas.id = '';
            }
            parmas.infoType = 3;
            // 调取接口
            upDataAddress(data, parmas);
          }
          if (type === 'email') {
            if (item.id) {
              parmas.email = item.email;
              parmas.id = item.id;
            } else {
              parmas.email = item.email;
              parmas.id = '';
            }
            parmas.infoType = 2;
            // 调取接口
            upDataEmail(data, parmas, type);
          }
          if (type === 'phoneType') {
            if (item.id) {
              parmas.type = item.type;
              parmas.id = item.id;
              parmas.phone = item.phone;
            } else {
              parmas.type = item.type;
              parmas.id = '';
            }
            parmas.infoType = 1;
            upDataPhone(data, parmas, type);
          }
          if (type === 'emailType') {
            if (item.id) {
              parmas.type = item.type;
              parmas.id = item.id;
              parmas.email = item.email;
            } else {
              parmas.type = item.type;
              parmas.id = '';
            }
            parmas.infoType = 2;
            upDataEmail(data, parmas, type);
          }

          // 电话\邮箱\地址接口
          // contactAll.getSave('', parmas).then(res => {
          //   if (res) {
          //     message.success('操作成功');
          //     perSonalInfo();
          //   }
          // });
        } else {
          let parmas = {
            userId: store.state.account.user.id,
            id: id,
            company: form.company,
            birthday: form.birthday,
            hobby: form.hobby,
            sex: form.sex,
            location: form.location,
          };

          // 处理有内容或失焦保存,没有内容是跳过
          for (let key in data) {
            if (key === type) {
              if (data[key] !== form[key]) {
                // 接口
                contactAll.contactUpata('', parmas).then(res => {
                  if (res) {
                    message.success('操作成功');
                    perSonalInfo();
                  }
                });
              } else {
                continue;
              }
            }
          }
        }
      },
    };
  },
};
</script>

<style scoped lang="less">
.information {
  margin: 16px 20px;
  //max-height: 662px;
  max-height: calc(100vh - 172px);
  overflow: auto;
  //padding-right: 8px;
  //padding-left: 2px;
  padding: 0px 8px 8px 2px;
}
.addGroup,
.addNetworking,
.phone,
.email {
  text-align: right;
}
.phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  .addPhone {
    cursor: pointer;
    color: #ff9634;
  }
}

.ant-input {
  background-color: #f5f6fa;
  border: 1px solid #ffffff;
}
.phoneCalss
  /deep/
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f5f6fa;
  border: 1px solid #ffffff;
}

.birthday
  /deep/
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f5f6fa;
  border: 1px solid #ffffff;
}
.birthday /deep/ .ant-input {
  background-color: #f5f6fa;
  border: 1px solid #ffffff;
}
.birthday /deep/ .ant-form-item {
  margin-bottom: 0px;
}

.phoneCalss /deep/ .ant-select {
  color: #97a5ce;
}
.phoneCalss /deep/ .ant-col-16 {
  max-width: 100%;
}
.ant-form-item {
  margin-bottom: 0px;
}
.phoneCalss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  .phoneCalssLeft {
    width: 63%;
  }
  .address {
    width: 100%;
  }
  .phoneCalssRight {
    width: 28%;
  }
  .delete {
    cursor: pointer;
  }
}
.groupCalss {
  background-color: #f5f6fa;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 8px 6px;
  margin-top: 10px;

  .groupContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //border: 1px solid #F2F3F7;
    //background: #FFFFFF;
    //padding: 8px 12px;
    border-radius: 4px;
    margin-right: 8px;
    margin-top: 8px;

    .groupDel {
      cursor: pointer;
      border: 1px solid #f2f3f7;
      background: #ffffff;
      padding: 12px;
      border-radius: 4px;
    }
    .name {
      border: 1px solid #f2f3f7;
      background: #ffffff;
      padding: 5px 12px;
      border-radius: 4px;
    }
  }
}

.mt-10 {
  margin-top: 10px;
}
.mt-10 /deep/ .ant-col-16 {
  max-width: 100%;
}

.gender
  /deep/
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f5f6fa;
  border: 1px solid #ffffff;
}
.gender /deep/ .ant-select {
  color: #97a5ce;
}
.gender /deep/ .ant-col-16 {
  max-width: 100%;
}
</style>
